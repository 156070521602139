import React, { useState, useEffect } from 'react';
import axios from 'axios';

import "./ImageSlider.css";

import backgroundImage from "./images/background.webp";

const ImageSlider = () => {

  const interval = 5 * 1000;
 
  const [images, setImages] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const api_key = process.env.REACT_APP_API_KEY;
    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}/${process.env.REACT_APP_IMAGE_FOLDER}`;

    const fetchImages = () => {
      axios({
        url: endpoint,
        method: 'GET',
        headers: {
          'x-api-key': api_key
        }
      })
      .then(res => {
        setImages(res.data.images);
        setCurrentImage(0);
        setLoading(false);
      });
      
    };
    fetchImages();
  }, []);

  useEffect(() => {
   
    const intervalId = setTimeout(() => {
     
      setCurrentImage((currentImage + 1) % images.length);

    }, interval);
    return () => clearTimeout(intervalId);
  }, [currentImage, images, interval]);

 

  return (

      <div className='background-screen' style={{ backgroundImage: `url(${backgroundImage})` }}>
        {loading ? (
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        ) : (
          
          <div className="full-screen">
              

 
            {images.map((image, index) => (
              <img
                key={image}
                src={image}
                className={`fade-in-out ${index === currentImage ? "visible" : "hidden"}`}
                alt={`Screen ${index}`}
              />
            
            ))}

          

          </div>

      )}
    </div>
    
  );


};

export default ImageSlider;