
import React, { useState } from 'react';
import jwt_decode from "jwt-decode";

import Menu from'./Menu';
import LoginButton from'./LoginButton';
import Login from'./Login';
import ImageSlider from'./ImageSlider';
import './App.css';

function App() {

  const [loggedIn, setLoggedIn] = useState(false);
  const [showLoginButton, setShowLoginButton] = useState(false);

  const onLogin = (token) => {

    setShowLoginButton(false);

    localStorage.setItem(process.env.REACT_APP_TOKEN_NAME, token);

  }

  const onLogout = () => {
  
    setLoggedIn(false);
    setShowLoginButton(false)

    localStorage.removeItem(process.env.REACT_APP_TOKEN_NAME);

  }

  const getRoles = (token) => {

    if (token === null)
      return [];

    const decoded = jwt_decode(token)
    const roles = token === null ? [] : decoded.roles;
    
    return roles;

  }

  const toggleLoginButton = ( login ) => {
  
    setLoggedIn(login);
    setShowLoginButton(login)
   
  }

  const roles = getRoles(localStorage.getItem(process.env.REACT_APP_TOKEN_NAME))

  return (
    <div className="App">
         
      <Login showLogin={showLoginButton} onLogin={onLogin} onLogout={onLogout}  />

      <ImageSlider />

      <Menu roles={roles} />

      <LoginButton loggedIn={loggedIn} onLogout={onLogout} setLoginCaption={toggleLoginButton}  />


    </div>
  );
}

export default App;
