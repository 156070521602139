import React, { useState } from 'react';
import "./Menu.css";

import menuImage from "./images/Yosemite_Sam.png";

const Menu = ( {roles}) => {

    console.log(roles);

    const isRole = (roleName) => {

        if (roles.includes(roleName))
            return 'visible';

        return 'hidden';
    }
    
    const [showMenu, setShowMenu] = useState(false);

    const url_base = process.env.REACT_APP_URL;

    return (
        <div
            className='menu-base'    
            onMouseEnter={() => setShowMenu(true)}
            onMouseLeave={() => setShowMenu(false)}
        >
        <img src={menuImage} alt='Menu' className='menu-image' />
        {showMenu && (
            <ul className='menu'>
                <li><a href={`${url_base}/stadium`}>Ballparks</a></li>
                <li><a href={`${url_base}/travel`}>Travel</a></li>
                <li style={{visibility: isRole('susan') }}><a href={`${url_base}/susan`}>Susan</a></li>
            </ul>
        )}
        </div>

    )
}

export default Menu;