
import React, { useState } from 'react';
import axios from "axios"

import "./Login.css";

const Login = ( props ) => {

    const {showLogin, onLogout, onLogin} = props;

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const isVisible = showLogin ? "visible" : "hidden";

    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };
  
    const handlePasswordChange = (event) => {
      setPassword(event.target.value);
    };
  

    const handleCancel = async (e) => {
      e.preventDefault();

      onLogout();

    }

    const handleLogin = async (e) => {
        e.preventDefault();

        const api_key = process.env.REACT_APP_API_KEY;
        const endpoint = `${process.env.REACT_APP_API_ENDPOINT}/${process.env.REACT_APP_AUTH}`;

        axios({
            url: endpoint,
            method: 'POST',
            headers: {
              'x-api-key': api_key
            },
            data: JSON.stringify({ email, password })
          })
          .then(res => {
            const { token } = res.data;
           
            onLogin(token);
  
          })
          .catch(e => {

            console.log("Login - Catch")
           
          })

    };

    if (!showLogin)
      return (<div />)

   return (
      
      <div style={{visibility: isVisible }} className="login-container">
        <form className="login-form" onSubmit={handleLogin}>
          <h1>Login</h1>
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" value={email} onChange={handleEmailChange} required />
          <label htmlFor="password">Password:</label>
          <input type="password" id="password" name="password" value={password} onChange={handlePasswordChange} required />
          <div className="button-container">
            <button type="submit">Log In</button>
            <button type="button" 
                    onClick={handleCancel}>Cancel</button>
          </div>
        </form>
      </div>
    );

   }

   export default Login;