import React, { useState } from 'react';

import "./LoginButton.css";

import loginImage from "./images/login.svg";
import logoutImage from "./images/logout.svg";

const LoginButton = ( { loggedIn, onLogout, setLoginCaption } ) => {

    const [showTooltip, setShowTooltip] = useState(false);

    const toggleDialog = () => {

        setLoginCaption(!loggedIn);

        if (loggedIn) {
            onLogout()
        }
       
    };

    const getImage = (showDialog) => {

        const isVisible = showTooltip ? "visible" : "hidden";

        return (
            <div 
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                <img src={showDialog ? logoutImage : loginImage} 
                    alt={showDialog ? "Logout" : "Logout"} 
                    className='login-image'  
                    onClick={toggleDialog} />

                <span className='tooltip' style={{visibility: isVisible}}>{showDialog ? "Logout" : "Login"}</span>
            </div>
        )
    }

    return (
        <div
            className='login-base'           
        >

            {getImage(loggedIn)} 

        </div>

    )

}


export default LoginButton;